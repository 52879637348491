import * as React from "react"
import Layout from "../common/layout.js"
import { GatsbyImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery, Link } from "gatsby"
import Seo from "../common/seo.js"

const About = () => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
        }
      }
      markdownRemark(frontmatter: { name: { eq: "about__bio" } }) {
        html
        frontmatter {
          title
        }
      }
      banner: file(relativePath: { eq: "img/about__banner.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)

  return (
    <Layout>
      <Seo
        title={`About ${data?.site?.siteMetadata?.title}`}
        description={data.markdownRemark.frontmatter.title}
      />
      <div className="relative">
        <GatsbyImage
          image={data.banner.childImageSharp.gatsbyImageData}
          width="720"
        />
        <h1
          className="fw1 tc f2 display absolute dn dib-ns"
          style={{
            bottom: "50%",
            left: "50%",
            fontSize: "8vh",
            color: "white",
            transform: "translate(-50%, -50%)",
          }}
        >
          About {data?.site?.siteMetadata?.title}
        </h1>
      </div>
      <div className="mw9 center flex flex-wrap pv5-l ph5-l w-100">
        <div className="mw7 w-100 pa2">
          <h1 className="display fw1 db lh-copy">
            {data.markdownRemark.frontmatter.title}
          </h1>
        </div>
        <div
          className="mw7 w-100 lh-copy serif pa2 flex flex-column justify-center f4"
          dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
        />
        <div className="mw7 w-100 pa2">
          <Link
            to="/blog"
            className="dib bg-dark-gray b near-white hover-bg-mid-gray pv3 ph4 ttu tracked sans-serif no-underline mv2"
          >
            Lees items
          </Link>
        </div>
      </div>
    </Layout>
  )
}

export default About
